import './App.css';
import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import MenuOne from './pages/MenuOne';
import MenuTwo from './pages/MenuTwo';
import Navigation from './components/Navigation';

const navLinks = [{
  name: "Home",
  link: "/home"
}, {
  name: "Privacy Policy",
  link: "/privacy-policy"
}, {
  name: "Contact Us",
  link: "/contact-us"
}];


function App() {
  return (
    <>
      <Navigation navLinks={navLinks} />
      <div className="font-['Manrope'] flex justify-center font-bold text-6xl py-6 mb-10">
        <img className="w-16 h-16 mr-2" src="/images/app-con.png" alt="logo" />
        <span className="">AndroMeta</span>
        {/* <div className="flex border-b border-black border-solid w-full h-1"></div> */}
      </div>
      
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/home" element={<Home />} />
        <Route exact path="/privacy-policy" element={<MenuOne />} />
        <Route exact path="/contact-us" element={<MenuTwo />} />
      </Routes>

    </>
  );
}

export default App;
