import React from "react";
import { NavLink } from "react-router-dom";
import { MenuIcon } from '@heroicons/react/solid';
import { useState } from "react";

function Navigation(props) {

    const menuItemsRef = React.createRef();
    const [menuToggleProperty, setMenuToggleProperty] = useState('hidden');

    function getNavLinks() {
        return props.navLinks.map((item, index) => {
            return(
                <NavLink key={item.name+index} 
                    to={item.link}
                    className="font-['BentonSansCond'] font-medium pt-2 pb-2 before:content-['|'] before:px-2 before:text-2xl"
                    >
                    <span>{item.name.toUpperCase()}</span>
                </NavLink>
            )
        });
    }

    function menuItems2() {
        return( props.navLinks.map( (item, index) => {
            return(
                <li key={index+item.name} className="border-b py-2 font-['BentonSansCond'] font-medium">{item.name.toUpperCase()}</li>
            );
        }) );
    }

    function toggleMenuItems() {
        if (menuToggleProperty === '') {
            setMenuToggleProperty('hidden');
        } else {
            setMenuToggleProperty('');
        }
    }

    return (
        <div className="border-b border-black">
            <nav className="flex sm:justify-left">
                {getNavLinks()}
            </nav>
            <div ref={menuItemsRef} className={`${menuToggleProperty} absolute z-50 bg-white border-2 border-black p-5`}>
                <ul>
                    {menuItems2()}
                </ul>
            </div>
        </div>
    );

}

export default Navigation;