import { useEffect, useState } from 'react';
import '../App.css';
import Card from '../components/Card';


function Home() {

  return (
    
    <div className="App max-w-[85%] mx-auto h-full">
      <p className='py-5'>
        AndroMeta is a curated content aggregator App for Android. This app is mainly built to learn and show my Android development skills.
        <img className="min-h-[20rem] max-h-[50rem] py-5" src="/images/1.png" alt="screenshot main" />
      </p>
         
      <p className='py-5'>
        This app displays all curated news, articles, tutorials and everything about Android. All the content is aggregated from publicly available RSS feeds from all major websites.

        <p className="py-3 font-bold">Note:</p> This app is for free without any authentication. Due sorce links are provided to the original content.
        <img className="min-h-[20rem] max-h-[50rem] py-5" src="/images/2.png" alt="screenshot main" />
      </p>

      <p className='py-5'>
        In today's world of technology keeping up with the information on the field you are interested is challenging. The only way possible is to aggregate content and have it delivered to your palm.
        <img className="min-h-[20rem] max-h-[50rem] py-5" src="/images/3.png" alt="screenshot main" />
      </p>

      <p className='py-5'>
        Hope this app will be one of the many tools you would be depending on.
        And the promise is to keep adding many features and content in the future.
        <img className="min-h-[20rem] max-h-[50rem] py-5" src="/images/4.png" alt="screenshot main" />
        </p>
    </div>
    
  );
}

export default Home;
