import React from "react";

function MenuTwo() {

    return(
        <div className="flex max-w-[85%] mx-auto h-full">
             <p className='py-5'>
                <span className="font-bold">How to contact us</span><br/>
                If you have any questions or suggestions, please contact me at:<br/>
                <span className="font-bold">polymath.developer@gmail.com</span>
            </p>
        </div>
    );

}

export default MenuTwo;