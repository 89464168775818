import { useEffect, useState } from 'react';
import '../App.css';
import Card from '../components/Card';


function MenuOne() {

  return(
      <div className="max-w-[85%] mx-auto h-full">
        <p className='py-5'>
           AndroMeta is a free Android news app that displays news articles from major technical sites inside of a web view. The app is hosted for free without any authentication.
        </p>

        <p className='py-5'>
            <span className="font-bold">What information do we collect?</span><br/>
            At this time, I do NOT collect any information.
        </p>

        <p className='py-5'>
            <span className="font-bold">How do we use your information?</span><br/>
            At this time, I do NOT use any information as I do NOT collect.
        </p>

        <p className='py-5'>
            <span className="font-bold">Who do we share your information with?</span><br/>
            At this time, I do NOT share any information as I do NOT collect.
        </p>

        <p className='py-5'>
            <span className="font-bold">How do we protect your information?</span><br/>
            At this time, I do NOT protect any information as I do NOT collect.
        </p>

        <p className='py-5'>
            <span className="font-bold">Cookies/ Links to other sites?</span><br/>
            As this app shows the RSS articles (which is content from other site), those pages may contain cookies or links. Note that these external sites are not operated by me and therefore strongly advise you to review the privacy of those websites.
            <span className="font-bold"><br/>
            I personally have NO control over and assume NO responsibility for the content, privacy policies or practices of any third-party sites or services.</span>
        </p>

        <p className='py-5'>
            <span className="font-bold">How to contact us</span><br/>
            If you have any questions about this privacy policy, please contact me at:<br/>
            <span className="font-bold">polymath.developer@gmail.com</span>
        </p>

        <p className='py-5'>
            <span className="font-bold">Changes to this privacy policy</span><br/>
            I may update this privacy policy from time to time. If I make any significant changes, I will notify you by email or through the app.
        </p>

        <p className='py-5'>
            <span className="font-bold">Effective date</span><br/>
            This privacy policy is effective as of May 21, 2023.
        </p>
      </div>
  );

}

export default MenuOne;
